
<mat-progress-bar *ngIf="pbarFlag"
    [mode]="pbarMode"
    color="accent"
    [value]="pbarProgress"
></mat-progress-bar>
<input type="file"
    [(ngModel)]="files"
    multiple accept="image/jpeg, image/png, image/bmp, image/eps, image/gif, image/tiff, JPEG, PNG, GOF,EPS,TIFF"
    class="form-control update-ignore"
    #fileinput
    (change)="fileAdded()"
    style="width: 100%;"
/>