<mat-form-field  class="w-100" appearance="fill">
  <mat-label>
    {{label}}
  </mat-label>
    <mat-chip-grid #chipList>
      <mat-chip-row
        *ngFor="let item of list"
        (removed)="remove(item)"
        >
        <a *ngIf="item.includes('http'); else elseBlock" [href]="item" target="_blank">
            {{item | UrlProcess}}
        </a> 
        <ng-template #elseBlock>
            {{item}}
        </ng-template>
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
    </mat-chip-grid>
    <input
      [placeholder]="placeholder"
      #input
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
      matChipInputAddOnBlur="true"
    />
</mat-form-field>