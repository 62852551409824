<div class="fxRow">
    <div class="container">
        @if (site) {
            <mat-card >
                <mat-card-header>
                    <mat-card-title>
                        <ng-template [ngIf]="! edit">
                            {{site.display_name}}
                        </ng-template>
                        <input  matInput 
                                *ngIf="edit && user.login" 
                                [value]="site.display_name" 
                                #box 
                                (keyup.enter)="nameChanged(box.value)"
                            />
                        <button mat-button 
                                *ngIf="! edit && user.login" 
                                (click)="edit=true"
                                matTooltip="Edit the display name"
                            >  
                            Edit
                        </button>
                    </mat-card-title>
                    <mat-card-subtitle>
                        {{site.geocode.formatted_address}}
                    </mat-card-subtitle>
                    <img mat-card-sm-image 
                        *ngIf="site && site.geocode.icon" 
                        [src]="site.geocode.icon" 
                        style="margin-left: auto;"
                    />
                </mat-card-header>
                <mat-card-content>
                    <mat-chip-set aria-label="Gun Count" class=" card-body align-middle">
                        <mat-chip *ngIf="site" 
                                color="primary" 
                                highlighted="true"
                                matTooltip="There {{site.guns.length>1?'are':'is'}} {{site.guns.length}} record{{site.guns.length>1?'s':''}} at this Site"
                        >{{site.guns.length}} Gun{{site.guns.length!=1?"s":""}}
                        </mat-chip>
                        @if (! user.login) {
                                <mat-chip  
                                highlighted="true" 
                                color="accent" 
                                matTooltip="For more information on this gun"
                            >
                                Login
                            </mat-chip>
                        }
                    </mat-chip-set>
                </mat-card-content>
                <mat-card-footer>
                    <ng-template [ngIf]="site.attribution.length>0">
                        <div  class="small" *ngFor="let att of site.attribution">
                            {{att}}
                        </div>
                    </ng-template>  
                </mat-card-footer>
                <mat-card-actions align="end">
                    <a mat-raised-button color="primary"
                        matTooltip="Goto the Google page for this site"
                        *ngIf="site.type=='google' && user.login" 
                        [href]="site.geocode.url" 
                        target="_blank"
                    >Google Page</a>
                    <a mat-raised-button color="primary"
                        matTooltip="Goto the website for this site"
                        *ngIf="site.geocode.website && user.login" 
                        [href]="site.geocode.website" 
                        target="_blank"
                    >Website</a>
                    <button mat-raised-button 
                        color="primary"
                        *ngIf="user.login"
                        matTooltip="Add a new record to this site"
                        routerLink="/new_record"
                        [queryParams]="{site_id:site.id}"
                    >Add Gun
                    </button>
                    @if (! user.login) {
                        <button 
                            mat-raised-button
                            routerLink="/login"
                            [queryParams]="{back:true}"
                            type="button" 
                            color="primary"
                        >
                            Login
                        </button>
                    }
                    <button mat-raised-button
                        type="button" 
                        color="primary"
                        (click)="link_here()"
                        data-toggle="popover"
                        data-content="A permanent link to this record has been copied to the clipboard"
                        matTooltip="create a Permalink to this record"
                    >
                    <mat-icon fontIcon="link"></mat-icon>
                    <span class="sm-hide">
                        Link here
                    </span>
                    </button>
                </mat-card-actions>
            </mat-card>
        }
        @else {
            <!-- No Site-->
            <div class="fxRow">
                <mat-spinner></mat-spinner>
            </div>
        }

        <!-- second row-->
        @if (user.login) {
            <div class="fxRow-sm">
                <!-- Map Pane-->
                <mat-card class="w-100">
                    <mat-card-header>
                        <mat-card-title>Location</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div id="map_canvas" class="map-container-inline" > 
                            <google-map width="100%"
                                        height="100%"
                                        [options]="options"
                                        (mapInitialized)="loaded($event)"
                            >
                                @for (marker of markerPositions; track marker.position) {
                                    <map-marker
                                        [position]="marker.position" 
                                        [icon]="marker.icon"  
                                        [options]="markerOptions"
                                    />
                                }
                            </google-map>

                        </div>
                    </mat-card-content>
                </mat-card>

                <!-- Gun List Pane-->
                <mat-card class="w-100">
                    <mat-card-header>
                        <mat-card-title>
                            Guns
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        @if (guns.length == 0) {
                            <mat-spinner></mat-spinner>
                        }
                        @else {
                            <div style="height: 50vh;">
                                <cdk-virtual-scroll-viewport 
                                    itemSize="64" 
                                    class="w-100 h-100 p-1"
                                    > 
                                    <div *cdkVirtualFor="let record of guns">
                                        <app-bcp-data-card 
                                            [routerLink]="['/database/entry']" 
                                            [queryParams]="{gunid:record.gunid}"
                                            [record]="record"
                                        ></app-bcp-data-card>
                                    </div>
                                </cdk-virtual-scroll-viewport>
                            </div>
                        }
                    </mat-card-content>
                </mat-card>



                <!-- Photo  Pane-->
                <!-- <div class="col-md-6">
                <mat-card>
                    <mat-card-title>Photos</mat-card-title>
                    <mat-card-content>
                        <app-bcp-photos 
                            [images]="images"
                            (newImage$)="acceptPhoto($event)"
                        ></app-bcp-photos>
                    </mat-card-content>
                </mat-card>
                </div> -->
            </div>
        }
    </div>
</div>

<!-- Floating Buttons -->
<button mat-fab  
    [matTooltip]="fabTooltip" 
    color="primary" 
    ngClass='fab' 
    (click)="submit()"
    routerLink="/new_record"
    [queryParams]="{site_id:site?.id}"
>
<mat-icon   color='accent' 
            ngClass='fab-text'>
    {{fabIcon}}
</mat-icon>
</button>

<div class="fbLeft">
    <button mat-raised-button 
            matTooltip="Goto previous Site in list"
            color="primary" 
            (click)="last()"
        >
        <mat-icon fontIcon="chevron_left"></mat-icon>
        <span class="sm-hide">
            Prev
        </span>
    </button>
    <button mat-raised-button 
            matTooltip="Goto next Site in list"
            color="primary" 
            (click)="next()"
        >
        <span class="sm-hide">
            Next
        </span>
        <mat-icon fontIcon="chevron_right"></mat-icon>
    </button>
</div>