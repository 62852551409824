<h1 mat-dialog-title>Use of Cookies</h1>
<div mat-dialog-content>
  <p>
    This site stores data on your computer for both 
    essential and optional purposes. This data is stored in the form 
    of browser storage and cookies.
  </p>
  <mat-grid-list cols="12">
    <mat-grid-tile colspan="9">
      Essential Cookies
    </mat-grid-tile>
    <mat-grid-tile colspan="3">
      <mat-slide-toggle disabled checked>

      </mat-slide-toggle>
    </mat-grid-tile>
    <mat-grid-tile colspan="9">
      Social Media Login
    </mat-grid-tile>
    <mat-grid-tile colspan="3">
      <mat-slide-toggle 
        checked
        (change)="change($event)"
      >
      </mat-slide-toggle>
    </mat-grid-tile>
  </mat-grid-list>
</div>
<div mat-dialog-actions>
  <button  mat-raised-button color="accent" mat-dialog-close cdkFocusInitial>Save Choices</button>
</div>