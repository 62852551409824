import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bcp-privacy',
  host: {
    class:'h-100'
  },
  templateUrl: './bcp-privacy.component.html',
  styleUrls: ['./bcp-privacy.component.scss']
})
export class BcpPrivacyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
