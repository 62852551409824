<div [formGroup]="gunForm">
    <mat-card>
        <mat-card-header>
            <mat-card-title>
                The Parts of the Cannon
            </mat-card-title>
            <mat-card-subtitle>
                Enter the details of the parts of the cannon or gun
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <mat-accordion class="example-headers-align w-100">
                    <mat-expansion-panel aria-label="Muzzle Codes">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h4 class="bg-primary text-white p-1">Muzzle</h4> 
                            </mat-panel-title>
                            <mat-panel-description>
                                <textarea disabled class="bg-site w-auto"  rows="1" name="moulding" id="moulding" formControlName="muzzle_code"></textarea>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <mat-button-toggle-group class="w-100" name="muzzle" formControlName="muzzle_code">
                            <div class="fxRow w-100" >
                                <mat-grid-list class="w-100" cols="3">
                                    <mat-grid-tile>
                                        <mat-button-toggle class="image-checkbox text-center" value="R">
                                            <span class=""><h1>R</h1><p><small>Tulip</small></p></span>
                                            <img class="card-img" src="../../assets/R.png">
                                        </mat-button-toggle>
                                    </mat-grid-tile>
                                    <mat-grid-tile>
                                        <mat-button-toggle class="image-checkbox text-center" value="F">
                                            <span class=""><h1>F</h1><p><small>Flared Cone</small></p></span>
                                            <img class="card-img"  src="../../assets/F.png">
                                        </mat-button-toggle>
                                    </mat-grid-tile>
                                    <mat-grid-tile>
                                        <mat-button-toggle class="image-checkbox text-center" value="T">
                                            <span class=""><h1>T</h1><p><small>Tapered Cone</small></p></span>
                                            <img class="card-img" src="../../assets/T.png">
                                        </mat-button-toggle>
                                    </mat-grid-tile>
                                    <mat-grid-tile>
                                        <mat-button-toggle class="image-checkbox  text-center" value="P">
                                            <span class=""><h1>P</h1><p><small>Cylinder</small></p></span>
                                            <img class="card-img" src="../../assets/P.png">
                                        </mat-button-toggle>
                                    </mat-grid-tile>
                                    <mat-grid-tile>
                                        <mat-button-toggle class="image-checkbox  text-center" value="E">
                                            <span class=""><h1>E</h1><p><small>Coronice</small></p></span>
                                            <img class="card-img" src="../../assets/E.png">
                                        </mat-button-toggle>
                                    </mat-grid-tile>
                                    <mat-grid-tile>
                                        <mat-button-toggle class="image-checkbox  text-center" value="X">
                                            <span class=""><h1>X</h1><p><small>Illegible or Missing</small></p></span>
                                        </mat-button-toggle>
                                    </mat-grid-tile>
                                </mat-grid-list>
                            </div>
                        </mat-button-toggle-group>
                    </mat-expansion-panel>
                    <mat-expansion-panel aria-label="Cascable Codes">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h4 class="bg-primary text-white p-1">Cascable</h4> 
                            </mat-panel-title>
                            <mat-panel-description>
                                <textarea disabled class="bg-site w-auto"  rows="1" name="moulding" id="moulding"  formControlName="cas_code"></textarea>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <mat-button-toggle-group class="w-100" name="cascable" formControlName="cas_code">
                        <div class="fxRow w-100">
                            <mat-grid-list class="w-100" cols="3">
                                <mat-grid-tile>
                                    <mat-button-toggle class="image-checkbox text-center" value="K">
                                        <span class=""><h1>K</h1><p><small>Conical</small></p></span>
                                        <img class="card-img" src="../../assets/K.png">
                                    </mat-button-toggle>
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <mat-button-toggle class="image-checkbox text-center" value="O">
                                        <span class=""><h1>O</h1><p><small>Spherical</small></p></span>
                                        <img class="card-img" src="../../assets/Sph.png">
                                    </mat-button-toggle>
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <mat-button-toggle class="image-checkbox text-center" value="U">
                                        <span class=""><h1>U</h1><p><small>Concave</small></p></span>
                                        <img class="card-img"  src="../../assets/U.png">
                                    </mat-button-toggle>
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <mat-button-toggle class="image-checkbox text-center" value="D">
                                        <span class=""><h1>D</h1><p><small>Flat</small></p></span>
                                        <img class="card-img" src="../../assets/Flat.png">
                                    </mat-button-toggle>
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <mat-button-toggle class="image-checkbox text-center" value="X">
                                        <span class=""><h1>X</h1><small>Illegible or Missing</small></span>
                                    </mat-button-toggle>
                                </mat-grid-tile>
                            </mat-grid-list>
                        </div>
                    </mat-button-toggle-group>  
                </mat-expansion-panel>
                <mat-expansion-panel aria-label="Button Codes">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h4 class="bg-primary text-white p-1">Button</h4> 
                        </mat-panel-title>
                        <mat-panel-description>
                            <textarea disabled class="bg-site w-auto"  rows="1" name="moulding" id="moulding"  formControlName="button_code"></textarea>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <mat-button-toggle-group class="w-100" name="button" formControlName="button_code">
                        <div class="fxRow w-100">
                            <mat-grid-list class="w-100" cols="3">
                                <mat-grid-tile>
                                    <mat-button-toggle class="image-checkbox text-center" value="N">
                                        <span class=""><h1>N</h1><p><small>Knob</small></p></span>
                                        <img class="card-img" src="../../assets/N.png">
                                    </mat-button-toggle>
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <mat-button-toggle class="image-checkbox text-center" value="L">
                                        <span class=""><h1>L</h1><p><small>Knob with Loop</small></p></span>
                                        <img class="card-img" src="../../assets/L.png">
                                    </mat-button-toggle>
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <mat-button-toggle class="image-checkbox text-center" value="G">
                                        <span class=""><h1>G</h1><p><small>Spherical</small></p></span>
                                        <img class="card-img" src="../../assets/G.png">
                                    </mat-button-toggle>
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <mat-button-toggle class="image-checkbox text-center" value="C">
                                        <span class=""><h1>C</h1><p><small>Cylinder</small></p></span>
                                        <img class="card-img"  src="../../assets/Cyl.png">
                                    </mat-button-toggle>
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <mat-button-toggle class="image-checkbox text-center" value="H">
                                        <span class=""><h1>H</h1><p><small>Horizontal Ring</small></p></span>
                                        <img class="card-img" src="../../assets/H.png">
                                    </mat-button-toggle>
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <mat-button-toggle class="image-checkbox text-center" value="V">
                                        <span class=""><h1>V</h1><p><small>Vertical Ring</small></p></span>
                                        <img class="card-img" src="../../assets/V.png">
                                    </mat-button-toggle>
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <mat-button-toggle class="image-checkbox text-center" value="E">
                                        <span class=""><h1>E</h1><p><small>Exended Knob</small></p></span>
                                        <img class="card-img" src="../../assets/Ext.png">
                                    </mat-button-toggle>
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <mat-button-toggle class="image-checkbox text-center" value="X">
                                        <span class="card-img"><h1>X</h1><small>Illegible or Missing</small></span>
                                    </mat-button-toggle>
                                </mat-grid-tile>
                            </mat-grid-list>
                        </div>
                    </mat-button-toggle-group>  
                </mat-expansion-panel>
                <mat-expansion-panel >
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h4 class="bg-primary text-white p-1">Moulding</h4> 
                        </mat-panel-title>
                        <mat-panel-description>
                            <textarea [disabled]="! edit" class="bg-site w-auto"  rows="1" name="moulding" id="moulding" onclick="event.stopPropagation()" [(value)]="mouldings" (input)="mouldingEdit($event)"></textarea>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                        <mat-card class="w-100">
                            <mat-card-header>
                                <img src="../../assets/mouldings.png"  alt="..." style="max-width: 600px">
                            </mat-card-header>
                            <mat-card-content>
                                <p >
                                    Add the Moulding codes for each ring in order - from the Base Ring to the Muzzle - using the RIng Divider "/" between each ring. To delete or change, edit the text above directly.
                                </p>
                            </mat-card-content>
                        </mat-card>
                        <div class="fxRow w-100">
                            <mat-grid-list class="w-100" cols="3">
                                <mat-grid-tile>
                                    <button mat-button class="image-checkbox text-center " (click)="mouldingChange('A')">
                                        <span class=""><h1>A</h1><p><small>Astragal</small></p></span>
                                        <img class="card-img" src="../../assets/A.png">
                                    </button>
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <button mat-button class="image-checkbox text-center " (click)="mouldingChange('B')">
                                        <span class=""><h1>B</h1><p><small>Band</small></p></span>
                                        <img class="card-img" src="../../assets/B.png">
                                    </button>
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <button mat-button class="image-checkbox text-center " (click)="mouldingChange('S')">
                                        <span class=""><h1>S</h1><p><small>Slope</small></p></span>
                                        <img class="card-img" src="../../assets/S.png">   
                                    </button> 
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <button mat-button class="image-checkbox col-4 text-center " (click)="mouldingChange('F')">
                                        <span class=""><h1>F</h1><p><small>Fillet</small></p></span>
                                        <img class="card-img" src="../../assets/F.png">
                                    </button> 
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <button mat-button class="image-checkbox col-4 text-center " (click)="mouldingChange('O')">
                                        <span class=""><h1>O</h1><p><small>Ogee</small></p></span>
                                        <img class="card-img" src="../../assets/O.png">
                                    </button> 
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <button mat-button class="image-checkbox col-4 text-center " (click)="mouldingChange('Q')">
                                        <span class=""><h1>Q</h1><p><small>Quadrant</small></p></span>
                                        <img class="card-img" src="../../assets/Q.png">
                                    </button> 
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <button mat-button class="image-checkbox col-4 text-center " (click)="mouldingChange('C')">
                                        <span class=""><h1>C</h1><p><small>Concave</small></p></span>
                                        <img class="card-img" src="../../assets/C.png">
                                    </button> 
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <button mat-button class="image-checkbox col-4 text-center " (click)="mouldingChange('/')">
                                        <span class=""><h1>/</h1><small>Ring Divider</small></span>
                                    </button> 
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <button mat-button class="image-checkbox col-4 text-center " (click)="mouldingChange('X')">
                                        <span class=""><h1>X</h1><small>Illegible or Missing</small></span>
                                    </button>
                                </mat-grid-tile>
                            </mat-grid-list>
                        </div>
                    </mat-expansion-panel>
            </mat-accordion>
        </mat-card-content>
    </mat-card>
</div>
 