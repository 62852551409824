
<!-- Gun Details row-->
<div class="fxRow-sm" [formGroup]="gunForm">
    <mat-card class="w-100 m-1">
        <mat-card-header>
            <mat-card-title>Basic Information</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="fxRow">
                <mat-form-field class="w-100 m-3" >
                    <mat-label>Type of Gun</mat-label>
                    <mat-select  formControlName="category" name="category">
                        <ng-container *ngFor="let category of DATA_VALUES.GUN_CATEGORIES" >
                            <mat-option 
                            *ngIf="category != 'All'"
                            [value]="category"
                            >
                                {{category}}
                            </mat-option>
                        </ng-container>
                    </mat-select> 
                </mat-form-field>
                <mat-form-field class="w-100 m-3">
                    <mat-label>Material</mat-label>
                    <mat-select formControlName="material" name="material">
                        <ng-container *ngFor="let material of DATA_VALUES.MATERIAL">
                            <mat-option 
                            *ngIf="material != 'All'"
                            [value]="material"
                        >
                            {{material}}
                        </mat-option>
                        </ng-container>
                    </mat-select> 
                </mat-form-field>
            </div>
           
            <mat-form-field 
                    appearance="fill"
                    class="w-100"
                >
                <mat-label>Short Desc</mat-label>
                <textarea matInput class="w-100" 
                    rows="2" 
                    name="description"
                    placeholder="Description"
                    formControlName="description"
                >
                </textarea>
            </mat-form-field>
            <div class="fxRow">
                <mat-form-field 
                    appearance="outline"
                    class="m-3"
                >
                <mat-label>Author</mat-label>
                <input  matInput 
                        type="text" 
                        name="name" 
                        value="{{user.login?gun.owner : '******' }}" 
                        readonly 
                        style="max-width:10em;"
                    >
                </mat-form-field>
                <mat-form-field 
                    appearance="outline"
                    class="m-3"
                >
                <mat-label>Date Recorded</mat-label>
                <input  matInput 
                        type="text" 
                        name="name" 
                        value="{{gun.date | date :'dd/MM/yy' }}" 
                        readonly 
                        style="max-width:10em;"
                    >
                </mat-form-field>
            </div>
            <div class="fxRow">
                <mat-slide-toggle
                    class="m-3 w-100"
                    formControlName="markings"
                    name="markings"
                    matTooltip="Are there any Markings on the Gun?"
                >
                    Markings?
                </mat-slide-toggle>
                <mat-slide-toggle
                    class="m-3 w-100"
                    formControlName="interpretation"
                    name="interpretations"
                    matTooltip="Is there an Interprative Information associated with the Gun?"
                >
                    Information board?
                </mat-slide-toggle>

                <mat-slide-toggle
                class="m-3 w-100"
                    formControlName="web_links"
                    name="Web Links"
                    matTooltip="Are here any web pages associated with this Gun?"
                >
                    Web Links?
                </mat-slide-toggle>
            </div>
            <mat-form-field 
                appearance="fill"
                class="w-100"
                *ngIf="gunForm.value.markings"
                >
                <mat-label>Describe the Markings</mat-label>
                <textarea matInput  
                    class="w-100"
                    name="mark_details"
                    rows='2'
                    placeholder="Details"
                    formControlName="mark_details"
                >
                </textarea>
            </mat-form-field>
            <mat-form-field 
                appearance="fill"
                class="w-100"
                *ngIf="gunForm.value.interpretation"
                >
                <mat-label>What does the board say?</mat-label>
                <textarea matInput  
                    class="w-100"
                    rows='2'
                    name="inter_details"
                    placeholder="Details"
                    formControlName="inter_details"
                >
                </textarea>
            </mat-form-field>
            <app-bcp-list-input
                *ngIf="user.login && gunForm.value.web_links"
                label="Associated Pages"
                [list]="gunForm.value.urls  || []"
                placeholder="Urls of Associated Pages"
                (updateList)="this.gunForm.patchValue({urls:$event})"
            >
            </app-bcp-list-input>
            <app-bcp-list-input
                *ngIf="user.login && editAttribution"
                label="Add copyright messages for any content included in this record"
                [list]="gunForm.value.attributions  || []"
                placeholder="add attribution"
                (updateList)="this.gunForm.patchValue({attributions:$event});editAttribution=false"
            >
            </app-bcp-list-input>
            <ng-template 
                [ngIf]="user.login && ! editAttribution"
            >
                <small class="text-muted" *ngFor="let item of gunForm.value.attributions">
                    <a 
                        *ngIf="item.includes('http') && ! item.includes(' ') ; else elseBlock"
                        [href]="item"
                        target="_blank"
                    >
                        {{item | UrlProcess}}
                        <ng-template ngIf="gunForm.value.attributions.length > 1">
                            ,
                        </ng-template>
                    </a>
                    <ng-template #elseBlock>
                        {{item}}
                        <ng-template ngIf="gunForm.value.attributions.length > 1">
                            ,
                        </ng-template>
                    </ng-template>
                </small>
                <button 
                    mat-button
                    (click)="editAttribution=true"
                    matTooltip="If this record contains or links to Copyright information, add an attribution here"
                >
                    Edit Attributions
                </button>
            </ng-template>
        </mat-card-content>
    </mat-card>
    <div class="fxCol w-100">
        <!-- Site Details-->
        <mat-card class="m-1" *ngIf="site">
            <mat-card-header>
                <mat-card-title>
                    Site
                </mat-card-title>
                <mat-card-subtitle>
                    {{site.display_name}}
                </mat-card-subtitle>
                <img mat-card-sm-image
                    style="margin-left: auto;"
                    *ngIf="site && site.geocode.icon"
                    [src]="site.geocode.icon"
                >
            </mat-card-header>
            <mat-card-content>
                <mat-chip-set aria-label="Gun Count" class=" card-body align-middle">
                    <mat-chip *ngIf="site" 
                            color="primary" 
                            highlighted="true"
                            matTooltip="There {{site.guns.length>1?'are':'is'}} {{site.guns.length}} record{{site.guns.length>1?'s':''}} at this Site"
                        >{{site.guns.length}} Gun{{site.guns.length!=1?"s":""}}
                    </mat-chip>
                </mat-chip-set>
            </mat-card-content>
            <mat-card-actions align="end">
                    <button mat-raised-button color="primary"
                        *ngIf="user.login && ! collapsed"
                        [routerLink]="['/database/site']" 
                        [queryParams]="{site_id:site.id}"
                        matTooltip="Goto the Details Page for this Site"
                    >Site
                    </button>
                    <a mat-raised-button color="primary" 
                        *ngIf="site.type=='google' && user.login && ! collapsed" 
                        [href]="site.geocode.url" 
                        target="_blank"
                        matTooltip="Goto the Google page for this site"
                    >Google
                    </a>
                    <a mat-raised-button color="primary" 
                        *ngIf="site.geocode.website && user.login && ! collapsed" 
                        [href]="site.geocode.website" 
                        target="_blank"
                        matTooltip="Goto the website for this site"
                    >Website
                    </a>
                    <button mat-raised-button
                        *ngIf="user.login && this.edit && ! collapsed"
                        color="primary" 
                        (click)="site=null"
                    >Change
                    </button>
                    <button mat-raised-button 
                        color="primary"
                        [mat-menu-trigger-for]="menu"
                        *ngIf="collapsed"
                    >
                        <mat-icon>menu</mat-icon>
                    </button>
                    <mat-menu x-position="before" #menu>
                        <mat-action-list>
                            <button mat-list-item
                                *ngIf="user.login"
                                [routerLink]="['/database/site']" 
                                [queryParams]="{site_id:site.id}"
                                matTooltip="Goto the Details Page for this Site"
                            >Goto Site
                            </button>
                            <a mat-list-item
                                *ngIf="site.type=='google' && user.login" 
                                [href]="site.geocode.url" 
                                target="_blank"
                                matTooltip="Goto the Google page for this site"
                            >Google Page
                            </a>
                            <a mat-list-item
                                *ngIf="site.geocode.website && user.login" 
                                [href]="site.geocode.website" 
                                target="_blank"
                                matTooltip="Goto the website for this site"
                            >Website
                            </a>
                            <button mat-list-item
                                *ngIf="user.login && this.edit"
                                (click)="site=null"
                            >Change
                            </button>
                        </mat-action-list>
                    </mat-menu>
            </mat-card-actions>
        </mat-card>
        <app-bcp-site-selector *ngIf="! site"
                            [location]="gun.location"
                            (newSite$)="siteChanged($event)"
                        >
                        </app-bcp-site-selector>
        <mat-card class="h-100 m-1">
            <mat-card-content>
                <mat-form-field 
                    appearance="fill"
                    class="w-100"
                    >
                    <mat-label>Description of Cannon Location</mat-label>
                    <textarea matInput  
                        class="w-100"
                        rows='2' 
                        name="context"
                        placeholder="Describe context"
                        formControlName="context"
                    >
                    </textarea>
                </mat-form-field>
                <div min-height="50px" *ngIf="user.login">
                    <mat-slide-toggle
                        formControlName="collection"
                        name="collection"
                    ></mat-slide-toggle>
                    <small class="text-wrap">
                        Is this gun part of a  museum, gallery or collection or on the grounds of a heritage site like a castle or recognised archeological site?
                    </small>
                </div>
                <ng-container *ngIf="user.login && gunForm.value.collection">
                    <mat-form-field 
                        appearance="fill"
                        class="w-100"
                        
                        >
                        <mat-label>Name of Museum / Gallery / Heritage Site / Collection</mat-label>
                        <textarea matInput  
                            class="w-100"
                            rows='1'
                            name="coll_name"
                            placeholder="Collection name"
                            formControlName="coll_name"
                        >
                        </textarea>
                    </mat-form-field>
                    <mat-form-field 
                        appearance="fill"
                        class="w-100"
                        
                        >
                        <mat-label>Collection Ref</mat-label>
                        <input matInput
                        class="w-100"
                        type="text"
                        name="coll_ref"
                        placeholder="Collection reference"
                        formControlName="coll_ref"
                    >
                    </mat-form-field>
                </ng-container>
            </mat-card-content>
        </mat-card> 
    </div>
</div>

<!-- second row-->
<div  class="fxRow-sm w-100">
    @if (user.login) {
         <!-- Map Pane-->
        <mat-card class="w-100 m-1">
            <mat-card-header>
                <mat-card-title>
                    Location
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-bcp-gmap
                    [options]="options"
                    [location]="gun.location"
                    [viewport]="viewport"
                    [gun]="gun"
                    (newLocation$)="locationUpdate($event)"
                >
                </app-bcp-gmap>
            </mat-card-content>
        </mat-card>

        <!-- Photo  Pane-->
        <mat-card class="w-100 m-1" >
            <mat-card-header>
                <mat-card-title>
                    Photos
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                       Add Photos
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title>Upload Photos</mat-card-title>
                            <mat-card-subtitle>Leave Blank if Not Needed</mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content>
                            <app-bcp-photo-select
                                [changeDetect]="changeDetect"
                            ></app-bcp-photo-select>
                        </mat-card-content>
                    </mat-card>
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title>Link to Existing Photographs</mat-card-title>
                            <mat-card-subtitle>Leave Blank if Not Needed</mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content>
                            <app-bcp-list-input
                                label="Enter the URLs for the Photos"
                                placeholder="add url (e.g. https://www.site.com/photo.jpg)"
                                (updateList)="newImageUrl($event)"
                            >
                            </app-bcp-list-input>
                        </mat-card-content>
                    </mat-card>
                    <mat-action-row>
                        <button mat-raised-button color="primary" (click)="acceptPhoto()">Upload</button>
                    </mat-action-row>
                  </mat-expansion-panel>
                <app-bcp-photo-gallery
                    [images]="images"
                ></app-bcp-photo-gallery>
            </mat-card-content>
        </mat-card>
    }
</div>
