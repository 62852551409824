import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bcp-help',
  templateUrl: './bcp-help.component.html',
  styleUrls: ['./bcp-help.component.css']
})
export class BcpHelpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
