<div class="fxRow">
    @if (currentUser) {
        <div class="container">
            <mat-horizontal-stepper 
                linear 
                labelPosition="right" 
                #stepper
                (selectionChange)="selectionChange($event)"
            >
                <mat-step errorMessage="Site is required." [completed]="steponeCompleted" >
                    <ng-template matStepLabel>Add Gun Location and Site</ng-template>
                    <ng-template matStepContent>
                        <div class="fxRow-sm">
                            <form id="location-entry">
                                <mat-card>
                                    <mat-card-header>
                                        <mat-card-title>Enter the Gun Location</mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content>
                                        <p class="mat-body "> 
                                            Enter co-ordinates, move the cannon on the map below to the right place or use your current location
                                        </p>
                                        <p class="mat-caption">
                                            <a href="/recording">
                                                How to Record a Cannon
                                            </a>
                                        </p>
                                    </mat-card-content>
                                </mat-card>
                                <app-bcp-gmap
                                    [options]="options"
                                    [location]="location"
                                    [viewport]="viewport"
                                    (newLocation$)="this.location = $event"
                                >
                                </app-bcp-gmap>
                            </form>
                            <div>
                                <app-bcp-site-selector
                                    [location]="location"
                                    [site]="site"
                                    (newSite$)="this.site = $event"
                                >
                                </app-bcp-site-selector>
                                <mat-card >
                                    <mat-card-header>
                                        <mat-card-title>
                                            Details
                                        </mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content>
                                        <div class="fxCol">
                                            <mat-form-field 
                                                appearance="fill"
                                                class="w-100"
                                            >
                                                <mat-label>Description of Gun Location</mat-label>
                                                    <textarea matInput 
                                                        placeholder="Describe where the Gun is located in the site"
                                                        [value]="context"
                                                        (input)="context=$event.target.value"
                                                    >
                                                    </textarea>
                                                    <button mat-icon-button
                                                        *ngIf="context" 
                                                        matSuffix  
                                                        aria-label="Clear" 
                                                        (click)="context=''"
                                                    >
                                                        <mat-icon>close</mat-icon>
                                                    </button>
                                            </mat-form-field>
                                            <mat-form-field 
                                                appearance="fill"
                                                class="w-100"
                                            >
                                                <mat-label>Gun Description</mat-label>
                                                <textarea matInput
                                                    placeholder="Describe the Gun itself"
                                                    [value]="description"
                                                    (input)="description=$event.target.value"
                                                >
                                                </textarea>
                                                <button mat-icon-button
                                                    *ngIf="description" 
                                                    matSuffix  
                                                    aria-label="Clear" 
                                                    (click)="description=''"
                                                >
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                            </mat-form-field>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>
                    </ng-template>
                </mat-step>

                <mat-step errorMessage="One Photo is Required" [completed]="steptwoCompleted">
                    <ng-template matStepLabel>Upload A Photo</ng-template>
                    <ng-template matStepContent>
                        <mat-card>
                            <mat-card-header>
                                <mat-card-title>Enter at least one photo - either an upload or a link</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <div class="fxRow-sm">
                                    <mat-card class="w-100">
                                        <app-bcp-photo-gallery
                                            [images]="images"
                                            (error)="imageError($event)"
                                        ></app-bcp-photo-gallery>
                                    </mat-card>
                                    <div class="fxCol w-100">
                                        <mat-card>
                                            <mat-card-header>
                                                <mat-card-title>Upload Photos</mat-card-title>
                                                <mat-card-subtitle>Leave Blank if Not Needed</mat-card-subtitle>
                                            </mat-card-header>
                                            <mat-card-content>
                                                <app-bcp-photo-select
                                                    [changeDetect]="changeDetect"
                                                    (newImage$)="newImage($event)"
                                                ></app-bcp-photo-select>
                                            </mat-card-content>
                                        </mat-card>
                                        <mat-card>
                                            <mat-card-header>
                                                <mat-card-title>Link to Existing Photographs</mat-card-title>
                                                <mat-card-subtitle>Leave Blank if Not Needed</mat-card-subtitle>
                                                <button mat-icon-button colour="primary" matTooltip="Info about Social Media Photos" (click)="showDialog()">
                                                    <mat-icon color="accent">
                                                        help
                                                    </mat-icon>
                                                </button>
                                            </mat-card-header>
                                            <mat-card-content>
                                                <app-bcp-list-input
                                                    label="Enter the URLs for the Photos"
                                                    placeholder="add url (e.g. https://www.site.com/photo.jpg)"
                                                    (updateList)="newImageUrl($event)"
                                                >
                                                </app-bcp-list-input>
                                                <app-bcp-list-input
                                                    label="Add copyright messages for any content included in this record"
                                                    placeholder="add attribution (e.g. (c) This Museum)"
                                                    (updateList)="attributions=$event"
                                                >
                                                </app-bcp-list-input>
                                            </mat-card-content>
                                        </mat-card>
                                        <mat-card>
                                            <mat-card-header>
                                                <mat-card-title>Link to Relevant Web Sites</mat-card-title>
                                                <mat-card-subtitle>Leave Blank if Not Needed</mat-card-subtitle>
                                            </mat-card-header>
                                            <mat-card-content>
                                                <app-bcp-list-input
                                                    label="Associated Pages"
                                                    placeholder="Urls of Associated Pages"
                                                    (updateList)="pages=$event"
                                                >
                                                </app-bcp-list-input>
                                            </mat-card-content>
                                            <mat-card-actions align="end">
                                                <button mat-raised-button color="primary" (click)="acceptPhoto()">Upload</button>
                                            </mat-card-actions>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </ng-template>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    }
    @else {

        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    You must be logged in to create new Records
                </mat-card-title>
            </mat-card-header>
            <mat-card-actions align="end">
                <button 
                            mat-raised-button
                            routerLink="/login"
                            [queryParams]="{back:true}"
                            type="button" 
                            color="primary"
                        >
                            Login
                        </button>
            </mat-card-actions>
        </mat-card>
    }
</div>

<!---  The FAB -->
<button mat-fab 
        color="primary" 
        ngClass='fab'
        [matTooltip]="fabTooltip"
        [disabled]="! fabActive"
        (click)="fabAction()"
    >
    <mat-icon 
        color='accent'
        ngClass='fab-text'
        *ngIf="fabActive"
    >
        {{fabIcon}}
    </mat-icon>
</button>