<div #div>
    <button draggable="false" title="Locate" aria-label="Locate" type="button" class="gm-control-active locate-control" (click)="onClick()">
        <mat-icon [ngClass]="{
        'locate-control': true,
        'locate-selected': locateStatus == 'on',
        'locate-moved': locateStatus == 'moved'
        }" >
            gps_fixed
        </mat-icon>
    </button> 
</div>
