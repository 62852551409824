<mat-card>
    <mat-card-header>
        <mat-card-title>Enter the correct Site </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p class="mat-body">
            The site is a named context for the location of the gun
        </p>
        <p class="mat-body"> 
            We use a standard set of location IDs provided by Google Maps and other sources to mark the nearest landmark. 
        </p>
        <mat-card *ngIf="! site && ! addSite">
            <mat-card-header>
                <mat-card-title>Known Sites</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p class="mat-body">
                    The following are the sites close to this location which are already in the system. Pick one or create a new site.
                </p>
                <cdk-virtual-scroll-viewport itemSize="50" class="viewport m-2"> 
                    <div *cdkVirtualFor="let item of closestSites">
                        <app-bcp-site-card 
                            (click)="setSite(item[0])"
                            [site]="item[0]"
                        ></app-bcp-site-card>
                    </div>
                </cdk-virtual-scroll-viewport>
            </mat-card-content>
        </mat-card>
        <mat-card *ngIf="addSite">
            <mat-card-header>
                <mat-card-title>Add Site</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p class="mat-caption">
                    Select a data source (default Google) and press "Fetch" to get the list of suitable sites.
                </p>
                <div class="fxRow">
                    <mat-form-field appearance="fill">
                        <mat-label>Data Source</mat-label>
                        <mat-select  [(value)]="source">
                            <mat-option *ngFor="let s of SOURCES" [value]="s">
                            {{s}}
                            </mat-option>
                        </mat-select>
                        </mat-form-field>
                    <button mat-raised-button color="primary" (click)="getSites()">Fetch</button>
                </div>
                @if (fetchSitesWaiting) {
                    <div class="fxRow">
                        <mat-spinner></mat-spinner>
                    </div>
                }
                @else {
                    <cdk-virtual-scroll-viewport itemSize="50" class="viewport my-2"> 
                        <div *cdkVirtualFor="let item of candidateSites">
                            <app-bcp-site-card 
                                (click)="setSite(item[0]); addSite=false"
                                [site]="item[0]"
                                [showGuns]="false"
                            ></app-bcp-site-card>
                        </div>
                    </cdk-virtual-scroll-viewport>
                }
            </mat-card-content>
        </mat-card>
        <mat-card *ngIf="site && ! addSite">
            <mat-card-header>
            <mat-card-title>{{site.display_name}}</mat-card-title>
            <mat-card-subtitle>{{site.geocode.formatted_address}}</mat-card-subtitle>
            <img mat-card-sm-image *ngIf="site && site.geocode.icon" [src]="site.geocode.icon" >
            </mat-card-header>
            <mat-card-content>
                <mat-chip-set aria-label="Gun Count" class=" card-body align-middle">
                    <mat-chip *ngIf="site" 
                            color="primary" 
                            selected
                        >{{site.guns.length}} Gun{{site.guns.length!=1?"s":""}}
                    </mat-chip>
                </mat-chip-set>
            </mat-card-content>
            <mat-card-actions align="end">
                <a mat-raised-button color="primary" 
                *ngIf="site.type=='google' && user.login" 
                [href]="site.geocode.url" 
                target="_blank"
                >Google Page
                </a>
                <a mat-raised-button color="primary" 
                *ngIf="site.geocode.website && user.login" 
                [href]="site.geocode.website" 
                target="_blank"
                >Website
                </a>
            </mat-card-actions>
        </mat-card>
    </mat-card-content>
    <mat-card-actions align="end">
        <button mat-raised-button color="primary" (click)="setSite(null); addSite=false">Select Known</button>
        <button mat-raised-button color="primary" (click)="setSite(null); addSite=true">Add New</button>
    </mat-card-actions>
</mat-card>
