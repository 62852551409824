
<div class="fxRow">
    <div class="container" >
        @if(! gun ){
            <div class="fxRow">
                <mat-spinner></mat-spinner>
                This might take a while
            </div>
        }
        @else {
            <form [formGroup]="gunForm" >
                <mat-card class="w-100">
                    <mat-card-header>
                        <mat-card-title>
                            Gun Reference : {{gun.gunid}}
                        </mat-card-title>
                        @if (gun.images[0]) {
                            @if(gun.images[0].s200) {
                                <img mat-card-sm-image
                                    [src]="gun.images[0].s200" 
                                    alt=""
                                    style="margin-left: auto;"
                                />
                            }
                            @else {
                                <img mat-card-sm-image 
                                    [src]="gun.images[0].original" 
                                    alt=""
                                    style="margin-left: auto;"
                                />
                            }
                        }
                    </mat-card-header>
                    <mat-card-content>
                        <mat-chip-set>
                            @if (! currentUser ) {
                                <mat-chip  
                                        highlighted="true" 
                                        color="accent" 
                                        *ngIf="! currentUser && gun"
                                        matTooltip="For more information on this gun"
                                    >
                                    Login
                                </mat-chip>
                            }
                            @if ( ! edit) {
                                <mat-chip 
                                    highlighted="true"
                                    color="warn"
                                >
                                    Read Only
                                </mat-chip>
                            }
                        </mat-chip-set>
                    </mat-card-content>
                    <mat-card-actions align="end">
                        <button mat-raised-button
                                type="button" 
                                color="primary"
                                (click)="link_here()"
                                data-toggle="popover"
                                data-content="A permanent link to this record has been copied to the clipboard"
                                matTooltip="create a Permalink to this record"
                            >
                            <mat-icon fontIcon="link"></mat-icon>
                            <span class="sm-hide">
                                Link here
                            </span>
                        </button>
                        @if (! currentUser) {
                            <button 
                                mat-raised-button
                                routerLink="/login"
                                [queryParams]="{back:true}"
                                type="button" 
                                color="primary"
                            >
                                Login
                            </button>
                        }
                    </mat-card-actions>
                </mat-card>
                <bcp-record-observer *ngIf="currentScreen == 'observer'"
                    [gun]="gun"
                    [gunForm]="gunForm"
                ></bcp-record-observer>
                <bcp-record-reporter *ngIf="currentScreen == 'reporter'"
                    [gun]="gun"
                    [gunForm]="gunForm"
                ></bcp-record-reporter>
                <bcp-record-surveyor *ngIf="currentScreen == 'surveyor'"
                    [gun]="gun"
                    [gunForm]="gunForm"
                ></bcp-record-surveyor>
            </form>
        }
    </div>
</div>

<!-- Floating Buttons -->

<button mat-fab  
    color="primary"
    ngClass='fab'
    (click)="submit()"
    [matTooltip]="fabTooltip"
    >
    <mat-icon color='accent'
    ngClass='fab-text'
    >
    {{fabIcon}}
    </mat-icon>
</button>

<div class="fbLeft">
    <button mat-raised-button
            color="primary"
            class="float-start mx-3  px-3"
            (click)="last()"
            matTooltip="Show the Previous Gun Record"
        >
        <mat-icon fontIcon="chevron_left"></mat-icon>
        <span class="sm-hide">
            Prev
        </span>
    </button>
    <mat-button-toggle-group
            appearance="legacy"
            *ngIf="user.login"
            [(value)]="currentScreen"
        > 
        <mat-button-toggle 
                id="observer" 
                role="tab" 
                aria-controls="list-observer" 
                value="observer"
                matTooltip="Show the Observer Level Details"
            >
            <span class="sm-show">
                O
            </span>
            <span class="sm-hide">
                Observer
            </span>
        </mat-button-toggle>
        <mat-button-toggle  
                id="reporter" 
                role="tab" 
                aria-controls="list-reporter" 
                value="reporter"
                matTooltip="Show the Reporter Level Details"
            >
            <span class="sm-show">
                R
            </span>
            <span class="sm-hide">
                Recorder
            </span>
        </mat-button-toggle>
        <mat-button-toggle
                id="surveyor"
                role="tab" 
                aria-controls="list-surveyor" 
                value="surveyor"
                matTooltip="Show the Surveyor Level Details"
            >
            <span class="sm-show">
                S
            </span>
            <span class="sm-hide">
                Surveyor
            </span>
        </mat-button-toggle>
    </mat-button-toggle-group>
    <button mat-raised-button 
            color="primary" 
            class="float-end mx-3  px-3" 
            (click)="next()"
            matTooltip="Show the next Gun Record" 
        >
        <span class="sm-hide">
            Next
        </span>
        <mat-icon fontIcon="chevron_right"></mat-icon>
    </button>
</div>
            