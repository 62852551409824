<mat-sidenav-container fullscreen>
  <mat-sidenav-content class="fxCol">
    <mat-toolbar color="primary">
      <button mat-button routerLink="/">
        <div >
          <img class="img-responsive m0 p0" height="50" src="../assets/CLEAN OS LOGO-640.png">
        </div>
      </button>
      <div fxLayout="row" fxHide fxShow.gt-xs>
          <button mat-button   routerLink="/database" matTooltip="Access the Gun database">Database</button>
          <button mat-button routerLink="/new_record" matTooltip="Create a New Record">New Record</button>
          <button mat-button routerLink="/" matTooltip="Details of the Version 2 Beta">About</button>
      </div>
      <span class="fill-remaining-space"></span>
      <p></p>
      <div fxLayout="row" fxHide fxShow.gt-xs>
        <button mat-button
            onClick="window.open('http://eepurl.com/hrRxUf')"
             matTooltip="Subscribe to our Newsletter"
          >
          Newsletter
      </button>
    </div>
      <button mat-icon-button routerLink="login" class="image"
          [matBadge]="badgeText"
          matBadgeSize="small"
          matBadgePosition="above"
          [matBadgeColor]="badgeColor" 
          [matTooltip]="badgeHint"
          [style.backgroundImage]="photoUrl"
        >
        <mat-icon *ngIf="! photoUrl" >account_circle</mat-icon>
      </button> 
      <button mat-button [mat-menu-trigger-for]="menu" fxShow fxHide.gt-xs>
      <mat-icon>menu</mat-icon>
      </button>
    </mat-toolbar>
    
    <mat-menu x-position="before" #menu>
      <mat-action-list>
        <button mat-list-item routerLink="/database" matTooltip="Access the Gun database">Database</button>
        <button mat-list-item routerLink="/new_record" matTooltip="Create a New Record">New Record</button>
        <button mat-list-item routerLink="/" matTooltip="Details of the Version 2 Beta">About</button>
        <button mat-list-item 
            onClick="window.open('http://eepurl.com/hrRxUf')" 
            matTooltip="Subscribe to our Newsletter"
          >
          Newsletter
        </button>
      </mat-action-list>
    </mat-menu>
    <div class="app-body">
      <router-outlet class="pt-5"></router-outlet>
      <div class="fxRow" >
        <p class="mat-caption" style="font-size:50%;">
          Website &#169;
          <a href="https://runette.co.uk"  target="_blank">
            Runette Software Ltd 2020/2022
          </a>
        </p>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
