<div class="fxRow">
    <div class="container">
        <mat-card class="w-100 m-1">
            <mat-card-header>
                <img matCardImageSmall src="../../assets/CLEAN OS LOGO-640.png"  alt="The Ordnance Society">
                <mat-card-title-group>
                    <mat-card-title>
                        Big Cannon Project
                    </mat-card-title>
                    <mat-card-subtitle>
                        A project from The Ordnance Society
                    </mat-card-subtitle>
                </mat-card-title-group>
            </mat-card-header>
            <mat-card-actions>
                <button  mat-raised-button
                    color="primary"
                    >
                    About the Project
                </button>
                 <a mat-raised-button
                    href="https://ordnancesociety.org.uk/wwi-artillery/"
                    color="primary"
                    target="_blank"
                    >
                    The Ordnance Society
                </a>
            </mat-card-actions>
        </mat-card>
        <mat-card class="w-100 m-1">
            <mat-card-header>
                <mat-card-title>
                    Gun Database
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="paral para01" >
                    <div class="img-back bg-dark">
                        <p class="img-text ">
                            The database allows individual recorders to upload their gun records, to improve them over time and to view all the other guns that have been recorded around the world. The project database is linked to a world map so that gun records can be viewed in geographical context.
                        </p>
                    </div>
                    <div class="fxRow">
                        <button mat-raised-button 
                            routerLink="database"
                            color="primary"
                            >
                            Explore the Guns
                        </button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card class="w-100 m-1">
            <mat-card-header>
                <mat-card-title>
                    Recording
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="paral para02" >
                    <div class="img-back bg-dark">
                        <p class="img-text" >
                            Our recording system allows you to record as little or as much data as you like. 
                            For Observer level records, Observers only need to upload a picture and a location. 
                            Recorders can create Silver level records by identifying key physical features of the gun. 
                            At the Surveyor level, you can provide full dimensions to achieve Gold and be a Surveyor.
                            Our world map of guns allows you to see where guns are and how much information about each one has been documented.
                        </p>
                    </div>
                    <div class="fxRow">
                        <button mat-raised-button 
                            routerLink="recording"
                            color="primary"
                            >
                            How to Record Guns
                        </button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card id="about" class="w-100 m-1">
            <mat-card-header>
                <mat-card-title>
                    About the Project
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p>
                    The Big Cannon Project aims to develop a centralised database of historical artillery guns of all types.
                </p>
                <p>
                    Launched in June 2018, the Project seeks to engage with scholars, curators, arms specialists, historical preservation organisations, 
                    individual researchers, and other key stakeholders in recording the history of important ‘big guns’, and making such data publicly available.
                </p>
                 <p>
                    Since 2024, the project has become part of 
                    <a href="https://ordnancesociety.org.uk/wwi-artillery/" target="_blank">
                        The Ordnance Society
                    </a> to expand the existing range of 
                        Artillery Surveys.
                 </p>
                 <p>
                    The Society administer the site and the data created by the project and are responsble
                    for setting the future direction of the project.
                 </p>
            </mat-card-content>
            <mat-card-actions>
                <a mat-raised-button 
                    color="primary" 
                    href="/privacy" 
                    >
                    Privacy Policy
                </a>
                <a mat-raised-button  
                    href="/tor"
                    color="primary"
                    >
                    Terms and Conditions of Access
                </a>
            </mat-card-actions>
        </mat-card>
        <div class="fxRow-sm p-3">
            <a class="thin" href="https://www.nauticalarchaeologysociety.org/" target="_blank"><img class="rounded mx-auto d-block" src="../../assets/NAS-Square-Logo-Dark-Blue.png" height="70px" alt="" /></a>
            <a class="thin" href="http://www.basiliscoe.com/" target="_blank"><img class="rounded mx-auto d-block" src="../../assets/basiliscoe.png" height="70px" alt="" /></a>
            <a class="thin" href="http://www.cismas.org.uk/" target="_blank"><img class="rounded mx-auto d-block" src="../../assets/cismas.png" height="50px" alt="" /></a>
            <a class="thin" href="https://msdsmarine.com/" target="_blank"><img class="rounded mx-auto d-block" src="../../assets/MSDS-768.png" height="50px" alt="" /></a>
            <a class="thin" href="https://runette.co.uk" target="_blank"><img class="rounded mx-auto d-block" src="../../assets/runette_long.png" height="30px" alt="" /></a>
        </div>
    </div>
</div>

