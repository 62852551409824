<h2 mat-dialog-title>Links to Web Images</h2>
<mat-dialog-content>
    The intention of this field is that you enter the URL for an image (that is 
    typical a JPEG or PNG) accessed across the web.

    You should note that, usually, social media sites such as Instagram, Facebook and Flickr
    do not provide a link to an image. They usually only let you link to a page which 
    displays the image, along with their branding and comments etc.

    This type of link will NOT work as an image link in this application and they should
    be added below as "Associated Pages".
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close cdkFocusInitial>Ok</button>
</mat-dialog-actions>