<div class="container my-5">
    <div class="jumbotron p-3 p-md-5 text-white rounded bg-dark">
    <div class="col-md-9 px-0">
        <h1 class="display-4 font-italic">Recording a Cannon</h1>
        <p class="lead my-3">Our recording system allows you to record as little or as much data as you like. The database has been set up to allow people to record information directly on site using a mobile device (tablet or smart phone) or from information they already have stored on their computer.
    </p><p class="lead my-3">
        Recording is split into three levels depending on the level of detail: Observer, Recorder and Surveyor.
    </p><p class="lead my-3">
        The observer level record only needs to a picture and a location. This is the basic information needed to create a record.
    </p><p class="lead my-3">
        At the recorder level, you can add basic measurements about the gun
    </p><p class="lead my-3">
        The surveyor level asks you to look at the cannon in more detail and record patterns and shapes.
    </p><p class="lead my-3">
        Once you have created a record you can come back to it and either add or modify the information.
    </p><p class="lead my-3">
        Our world map of guns allows you to see where guns are, and how much information about each one has been documented You can view basic information about a record without logging into the site but to see full the full information you need to register and log in  

        </p>
        <a class="btn btn-small btn-primary" role="button" href="http://www.3hconsulting.com/techniques/TechRecordingCannon.html"> Learn More about Recording Guns</a>
    </div>
    </div>

    <hr class="featurette-divider">
    <div class="row featurette">
        <div class="col-md-7">
            <img class="featurette-image img-fluid mx-auto" src="/assets/img/Slide1.JPG" alt="Generic placeholder image">
            <img class="featurette-image img-fluid mx-auto" src="/assets/img/Slide2.JPG" alt="Generic placeholder image">
            <img class="featurette-image img-fluid mx-auto" src="/assets/img/Slide3.JPG" alt="Generic placeholder image">
        </div>
        <div class="col-md-5">
        <h2 class="featurette-heading"><span class="text-muted">Login in</span></h2>
        <p class="lead">To create new records, you must be logged into the site.</p>
        </div>
        
    </div>

    <hr class="featurette-divider">

            <div class="row featurette">
            <div class="col-md-5">
                <h2 class="featurette-heading"><span class="text-muted">Check the Database</span></h2>
                <p class="lead">The First step is to check the database to see what is in the database already. Look on the map at the current location and see if the cannon is already in the database. You can use the ‘target’ button the map screen to use your phone to zoom into your current location. If the record already exists - you can add photos but cannot change any details at the moment. That ability should be coming soon, when we have worked out how to keep track of changes.</p>
            </div>
            <div class="col-md-7">
                <img class="featurette-image img-fluid mx-auto" src="/assets/img/Slide0.JPG" alt="Generic placeholder image">
            </div>
            </div>

    <hr class="featurette-divider">

            <div class="row featurette">
            <div class="col-md-7">
                <img class="featurette-image img-fluid mx-auto" src="/assets/img/Slide4.JPG" alt="Generic placeholder image">
                <img class="featurette-image img-fluid mx-auto" src="/assets/img/Slide5.JPG" alt="Generic placeholder image">
            </div>
            <div class="col-md-5">
                <h2 class="featurette-heading"><span class="text-muted">Entering a location (observer)
                </span></h2>
                <p class="lead">When you enter a new record, you will be taken to the location page. You can either enter the location manually or by moving the cannon icon to your cannon’s location.
                </p> <p class="lead">If you are at the site of the cannon and are connected to the internet and have a GPS enabled device, you can use your current location to record where the cannon is.
                </p> <p class="lead">Once you have recorded and accepted the cannon location you will be asked to provide the correct site name. We use a standard set of location IDs provided by Google Maps to mark the nearest landmark. Scroll up and down the list to pick the most appropriate (Use the town as a default if you are not sure of the exact location description).
                </p> <p class="lead">If you made a mistake and chose the wrong location, don’t worry. You get a chance to fix that at the end of the process.
                </p>
            </div>
            </div>
            
    <hr class="featurette-divider">
    <div class="row featurette">
        <div class="col-md-5">
        <h2 class="featurette-heading"><span class="text-muted">Entering an image (observer)</span></h2>
        <p class="lead">Once you have accepted the location you will be taken to the “ Add photo of the gun” screen.
        </p><p class="lead">Click “Browse” and this will take you to where you can access images of your gun on your computer. If you are using a smartphone and are out in the field you also have the option of using your camera to take “live” photos.
        </p><p class="lead">Once you have selected your image press “upload”. 
        </p><p class="lead">We suggest your first photo is of the whole gun
            </p>
        </div>
        <div class="col-md-7">
            <img class="featurette-image img-fluid mx-auto" src="/assets/img/Slide6.JPG" alt="Generic placeholder image">
        </div>
    </div>
    
    <hr class="featurette-divider">
    <div class="row featurette">
        <div class="col-md-5">
            <img class="featurette-image img-fluid mx-auto" src="/assets/img/Slide7.JPG" alt="Generic placeholder image">
        </div>
        <div class="col-md-7">
        <h2 class="featurette-heading"><span class="text-muted">Recording basic information (observer)</span></h2>
        <p class="lead">
            The Observer screen allows you to enter basic information about the gun and to upload more photos . We suggest uploading photos in ones and twos at the moment. 
        </p>
            <strong>Basic information</strong>
            <dl class="row">
                <dt class="col-4"><strong>Type of gun</strong></dt>
                <dd class="col-8">use the drop-down menu to choose the type of gun</dd>
                <dt class="col-4"><strong>Material </strong></dt>
                <dd class="col-8">use the drop-down menu to identify the material the gun is made from. </dd>
                <dt class="col-4"><strong>Short description </strong></dt>
                <dd class="col-8">add a short description of the gun, highlighting important features and any information known (calibre etc). If you are not adding the photographs “live” add the date photograph was taken. </dd>    
                <dt class="col-4"><strong>Author </strong></dt>
                <dd class="col-8">pre-populated ( if anon comes up overwrite with recorder name)</dd>
                <dt class="col-4"><strong>Date recorded </strong></dt>
                <dd class="col-8">pre-populated</dd>
                <dt class="col-4"><strong>Markings and information board </strong></dt>
                <dd class="col-8">highlight radio buttons if you can add information. Use the pop-up boxes to add information.</dd>
            </dl>
            
            
            <strong>Context</strong>
            <dl class="row">
                <dt class="col-4"><strong>Name</strong></dt>
                <dd class="col-8">defaults to Google location but you can overwrite it to give more detailed information about the gun location.</dd>
                <dt class="col-4"><strong>Location</strong></dt>
                <dd class="col-8">set to the Goggle location. Scroll up or down to modify.</dd>
                <dt class="col-4"><strong>Description of the cannon location</strong></dt>
                <dd class="col-8">add a short description of the specific location.</dd>
                <dt class="col-4"><strong>is this gun part of a museum….</strong></dt>
                <dd class="col-8">use the radio button to highlight and then add information about the collection. Add the collection reference if known.</dd>
            </dl>
        
            <strong>Photos</strong> 

            Use the browse button to add more photographs of the cannon
            
        </div>
    </div>
    
    <hr class="featurette-divider">
    <div class="row featurette">
        <div class="col-md-5">
        <h2 class="featurette-heading"><span class="text-muted">Recording Measurements (recorder)</span></h2>
        <p class="lead">
            The recorder tab allows you to enter physical measurements about the gun. 
        </p><p class="lead">Use the radio button to decide on measurement units ( m / mm ). 
        </p><p class="lead"> The drawing currently shows a “standard” cannon but as the project develops we hope to add recording sheets based around carronades & mortars.
        </p><p class="lead">In the meantime, please add whatever measurements you can for the gun you are recording.
        </p><p class="lead">After entering data press the save icon before moving on.
        </p>
        </div>
        <div class="col-md-7">
        <img class="featurette-image img-fluid mx-auto" src="/assets/img/Slide8.JPG" alt="Generic placeholder image">
        </div>
    </div>
    
    <hr class="featurette-divider">
    <div class="row featurette">
        <div class="col-md-7">
            <img class="featurette-image img-fluid mx-auto" src="/assets/img/Slide9.JPG" alt="Generic placeholder image">
        </div>
        <div class="col-md-5">
        <h2 class="featurette-heading"><span class="text-muted">Analysing patterns and shapes  (surveyor)</span></h2>
        <p class="lead">
            The surveyor tab allows you to input  more detailed information about the cannon.
            It has four sections.
        </p>
        <dl class="row">
            <dt class="col-4"><strong>Moulding codes</strong></dt>
            <dd class="col-8">details about the moulding patterns on the reinforcing rings</dd>
            <dt class="col-4"><strong>Muzzle code</strong></dt>
            <dd class="col-8">generic shape of the muzzle</dd>
            <dt class="col-4"><strong>Cascable code</strong></dt>
            <dd class="col-8">generic shape of the cascable</dd>
            <dt class="col-4"><strong>Button code </strong></dt>
            <dd class="col-8">generic shape of the button</dd>
        </dl>

            <p class="lead">Clicking on a box expands it and shows you drawings to help you complete the code
            </p><p class="lead">After entering data press the save icon before moving on.
            
        </p>
        </div>

    </div>
    
    <hr class="featurette-divider">
</div>