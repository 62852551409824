
<mat-card>
    <mat-card-content>
        <mat-grid-list [cols]="showGuns ? numberOfCols + 2 : numberOfCols" rowHeight="64px">
            @if(showImage) {
                <mat-grid-tile colspan="2">
                    <img class="card-img m-1" [src]="site.geocode.icon ? site.geocode.icon : '../assets/32x32.png'" />
                </mat-grid-tile>
            }
            <mat-grid-tile colspan="8">
                <mat-card class="w-100">
                    <mat-card-header>
                        <mat-card-title>
                            {{site.display_name | truncate:17}}
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <p class="mat-caption">
                            {{site.geocode.formatted_address | truncate:20}}
                        </p>
                    </mat-card-content>
                </mat-card>
            </mat-grid-tile>
            @if (showGuns) {
                <mat-grid-tile colspan="2">
                    <mat-chip-set aria-label="Gun Count" class=" card-body align-middle">
                        <mat-chip *ngIf="site" 
                                color="primary" 
                                highlighted=""
                                matTooltip="There {{site.guns.length>1?'are':'is'}} {{site.guns.length}} record{{site.guns.length>1?'s':''}} at this Site"
                            >{{site.guns.length}} Gun{{site.guns.length!=1?"s":""}} 
                        </mat-chip>
                    </mat-chip-set>
                </mat-grid-tile>
            }
        </mat-grid-list>
    </mat-card-content>
</mat-card>